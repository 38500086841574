import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/article-card"
import ArticleTagList from "../components/article-tag-list"
import * as styles from "./article.module.scss"

export default function Article({ data, location }) {
    var articles = data.wagtailbase.pages
    // sort by article date
    articles.sort(function(a, b) {
        if(a.date < b.date) return 1;
        if(a.date > b.date) return -1;
        return 0;
    });

    return (
        <Layout location={location}>
            <SEO
                title="記事一覧"
            />
            <article>
                <header className={styles.articleListHeader}>
                    <h1>
                        記事一覧
                    </h1>
                    <section className={styles.articleTagsContainer}>
                        <ArticleTagList />
                    </section>
                </header>
                <section className={styles.articleCardsContainer}>
                    {articles.map(article => {
                        return <ArticleCard article={article} key={article.slug} />
                    })}
                </section>
            </article>
        </Layout>
    )
}


export const query = graphql`
    query {
        wagtailbase {
            pages(contentType: "articles.ArticleDetailPage") {
              ... on WagtailBase_ArticleDetailPage {
                slug
                title
                overview
                date
                bannerImage {
                  url
                  thumbnailWebp: rendition(fill:"800x420", format: "webp") {
                    url
                  }
                  thumbnailJpeg: rendition(fill:"800x420", format: "jpeg") {
                    url
                  }
                }
              }
            }
        }
    }
`
